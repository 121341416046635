import gql from "graphql-tag";

export const GET_MODIFY_PRICE_BY_SIGNUPID = gql`
  query GetModifyPriceBySignupId($signupId: ID!) {
    LDPConfigQueryGroup {
      ModifyPriceCurrent(SignupId: $signupId)
    }
  }
`;

export const UPDATE_MODIFY_PRICE_BY_SIGNUPID = gql`
  mutation ModifyPriceUpdate($modifyPrice: ModifyPriceInputType!) {
    LDPConfigMutationGroup {
      ModifyPriceUpdate(ModifyPrice: $modifyPrice)
    }
  }
`;
