import React, { useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute } from "../components";
import LeadSearchForm from "../views/leads/leadSearchForm";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { RootState } from "../state";
import { TabProps, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";
import { isRoleExternalAccountManager } from "../common/utils/roles";

interface Props {
  dispatch: Function;
  leadsTabs: any;
  loggedInUser ?: any;
}

const TAB_CONTAINER = "leads-list-tabs";

const Leads = ({ leadsTabs, dispatch, loggedInUser }: Props) => {
  const isUserExternalAccountManager = isRoleExternalAccountManager(loggedInUser);  
  if (isUserExternalAccountManager) {
    return (<h2 style={{color: "red", marginTop: "5rem"}}>Sorry you do not have access for this page right now.</h2>);
  }

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [_leadsTabs, setLeadTabs] = useState<TabProps[]>([]);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (!leadsTabs.tabContainers[TAB_CONTAINER]) {
      //Initialize Redux tabsContainer for leadss
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Lead Search",
          tabId: "lead-search",
          content: <LeadSearchForm />,
          permalink: `/leads/`,
        },
      ];

      const initialLeadsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialLeadsTabContainer));
    } else {
      if (leadsTabs.tabContainers[TAB_CONTAINER]) {
        setLeadTabs(leadsTabs.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(leadsTabs.tabContainers[TAB_CONTAINER].tabFocus);
      }
    }
  }, [leadsTabs])

  return (
    <ContentContainer>
      {loggedInUser && (<TabsContainer
        tabs={_leadsTabs}
        onCloseTab={setLeadTabs}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />)}
      
    </ContentContainer>
  );
};

const LeadsWrapper = connect((state: RootState) => ({
  leadsTabs: state.tabsSection,
  loggedInUser: state.loggedInUser
}), null)(Leads);

export default ({ location }: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={LeadsWrapper} path="/leads" pagetitle="LDP Leads Lookup" />

      </Router>
    </Layout>
  );
};


