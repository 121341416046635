import { MUIDataTableColumn } from "mui-datatables";

export const findMuiRowValue = (muiColDefs: MUIDataTableColumn[], findValues: string[], rowData: any[]) => {
  const values: any[] = [];

  for(const colname of findValues){
    const index = muiColDefs.findIndex((colDef) => colname === colDef.name);
    if(index > -1){
      values.push(rowData[index]);
    }
  }

  return values;
}