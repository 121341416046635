import gql from "graphql-tag";

export const GET_VERTICAL = gql`
    query GetVertical($where: String) {
        LDPConfigQueryGroup {
            Vertical(limit:9999, where: $where) {
              VerticalId
              VerticalName
              UserId
              CreatedDate
              ModifiedDate
            }
        }
    }
`;  

export const GET_SUBVERTICAL = gql`
    query GetSubVertical($where: String) {
        LDPConfigQueryGroup {
            SubVertical(limit:9999, where: $where) {
              SubVerticalId
              SubVerticalName
              UserId
              CreatedDate
              ModifiedDate
              VerticalId
            }
        }
    }
`;  