import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GET_LEAD_CONFIG_HISTORY } from "../../common/models/leadDataLookup";
import { ContentLoading, LDPUIDataTable } from "../../components";
import { useQuery } from "@apollo/react-hooks";
import { dateToPreferredTimezone } from "../../common/utils/date";

interface props {
    SignupId: number;
}

const LeadConfigHistoryModal = ({
    SignupId
}: props) => {
    
    const {data, loading} = useQuery(GET_LEAD_CONFIG_HISTORY, {
        fetchPolicy: 'network-only',
        variables: {
            signupId: SignupId
        }
    });
    

    const columnsMUI = [
        // {
        //     "name": "BuyerSaleQueueId",
        //     "label": "Buyer", 
        //     "options": {
        //         "filter": false,
        //         "display": true,                
        //         customBodyRender: (value: any, tableMeta: any) => {
        //             const config = tableMeta.tableData[tableMeta.rowIndex];
        //             const buyerContractId = config?.BuyerSaleQueuePriceChangeId;                    
        //             const response = JSON.parse(config?.OriginalBuyerUpdateResponse ?? '{}');
        //             const context = response?.PriceUpdateData?.filter(({BuyerContractId}: any) => BuyerContractId == buyerContractId)?.pop() ?? undefined;
                    
        //             return context?.BuyerName ?? "";                    
        //             //console.log(config, JSON.parse(config?.NewBuyerUpdateResponse ?? '{}'));
        //         }

        //     }           
        // }, 
        {
            "name": "BuyerContractId",
            "label": "Buyer Contract Id",  
            "options": {
                "filter": false,
                "display": true,                
            }         
        }, 
        {
            "name": "LdpBuyername",
            "label": "Ldp Buyername",  
            "options": {
                "filter": false,
                "display": true,                
            }         
        }, 
        {
            "name": "SignupId",
            "label": "Signup Id",  
            "options": {
                "filter": false,
                "display": true,                
            }         
        }, 
        {
            "name": "Amount",
            "label": "Amount",  
            "options": {
                "filter": false,
                "display": true,                
            }         
        }, 
        {
            "name": "IsPriceChange",
            "label": "Is Price Change",  
            "options": {
                "filter": false,
                "display": true,      
                customBodyRender: (value: any) => value ? 'yes' : 'no'          
            }         
        }, 
        {
            "name": "UserId",
            "label": "User ID",  
            "options": {
                "filter": false,
                "display": true,                
            }         
        }, 
        // {
        //     "name": "OriginalBuyerUpdateResponse",
        //     "label": "Old Price",
        //     "options": {
        //         "filter": false,
        //         "display": true,
        //         customBodyRender: (value: any, tableMeta: any) => {
        //             const config = tableMeta.tableData[tableMeta.rowIndex];
        //             const buyerContractId = config?.BuyerSaleQueuePriceChangeId;                    
        //             const response = JSON.parse(value ?? '{}');
        //             const context = response?.PriceUpdateData?.filter(({BuyerContractId}: any) => BuyerContractId == buyerContractId)?.pop() ?? undefined;                    
        //             return context?.Amount ?? "";                                        
        //         }                
        //     }
        // }, 
        // {
        //     "name": "NewBuyerUpdateResponse",
        //     "label": "New Price", 
        //     "options": {
        //         "filter": false,
        //         "display": true,
        //         customBodyRender: (value: any, tableMeta: any) => {
        //             const config = tableMeta.tableData[tableMeta.rowIndex];
        //             const buyerContractId = config?.BuyerSaleQueuePriceChangeId;                    
        //             const response = JSON.parse(value ?? '{}');                    
        //             const context = response?.PriceUpdateData?.filter(({BuyerContractId}: any) => BuyerContractId == buyerContractId)?.pop() ?? undefined;                                        
        //             return context?.Amount == 0 ? "-" : (context?.Amount ?? "");
        //         }                
        //     }          
        // }, 
        {
            "name": "CreatedDate",
            "label": "Created Date",  
            "options": {
                "filter": false,
                "display": true,
                customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value))                
            }          
        },      
        {
            "name": "ModifiedDate",
            "label": "Modified Date",  
            "options": {
                "filter": false,
                "display": true,
                customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value))                
            }          
        },         
    ];

    const options: any = {
        filterType: "checkbox",
        selectableRows: "none",
        responsive: "vertical",
      };

    return (<Grid container spacing={1}>
        <Grid item xs={12}>
          {loading && (<ContentLoading showTip={true} />)}
          {data && (<LDPUIDataTable
              ldpTableId="buyer-group-list"
              restoreFilters={true}
              title={"Lead Config History"}
              data={data?.LDPConfigQueryGroup?.GetHistoryBuyerSaleQueuePriceChange}
              columns={columnsMUI}
              options={options}
          />)}          
        </Grid>        
      </Grid>);
}

export default LeadConfigHistoryModal;