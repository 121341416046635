import React, { useEffect, useState } from "react";
import {
    makeStyles,
    withStyles,
    Theme,
    createStyles,
    Grid,
    Paper,
    Typography,
    Divider,
    Tooltip,
} from "@material-ui/core";
import { queryLoad } from "../../components";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_INSURANCE_AUTO } from "../../common/models/insuranceAuto";
import { GetInsuranceAuto, GetInsuranceAuto_LDPIngestQueryGroup_InsuranceAuto } from "../../common/models/types/GetInsuranceAuto";
import { GET_LEAD_DETAIL } from "../../common/models/leadDataLookup";
import { GetLeadDetail, GetLeadDetail_LDPIngestQueryGroup } from "../../common/models/types/GetLeadDetail";

interface LeadViewModalProps {
    LeadDataId: String | null;
    VerticalId: string;
    SubVerticalId: string;
    close: Function;
    FetchEDW?: boolean;
}

interface TypoProps {
    title: string;
    value: string | any;
}

const isValidUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        return false;
    }
    return true;
};

const Typo = ({ title, value }: TypoProps) => {
    if (title == "Payload") {
        return (
            <Grid container>
                <Grid item xs={12} md={3} lg={2} style={{maxWidth: "320px", overflowWrap: "break-word"}}><strong>{title}:</strong></Grid>
                <Grid item xs={12} md={9} lg={10}><textarea style={{ height: "230px", width: "100%" }} readOnly={true}>{value}</textarea></Grid>
             </Grid>);
    }
    let finalValue;
    if (typeof value === 'boolean') {
        finalValue = (value ? "Yes" : "No")
        if (title == "Accident") {
            finalValue = value.toString();
        }
    } else if (isValidUrl(value)) {
        finalValue = <a target="_blank" href={value}>{value}</a>
    } else if (!value) {
        finalValue = "n/a";
    } else {
        finalValue = value;
    }
    
    return (<Grid container>
        <Grid item xs={12} md={3} lg={2} style={{maxWidth: "320px", overflowWrap: "break-word"}}><strong>{title}:</strong></Grid>
        <Grid item xs={12} md={9} lg={10} >{finalValue}</Grid>
    </Grid>);
}

const LeadViewModal = ({
    LeadDataId,
    VerticalId,
    SubVerticalId,
    close,
    FetchEDW,
}: LeadViewModalProps) => {
    const classes = useStyles();
    const [getLeads, { data, called, error, loading, refetch }] = useLazyQuery<GetLeadDetail>(GET_LEAD_DETAIL);
    const [lead, setLead] = useState<GetInsuranceAuto_LDPIngestQueryGroup_InsuranceAuto | undefined | null>();

    useEffect(() => {
        getLeads({
            variables: {
                LeadDataId: LeadDataId || null,
                VerticalId: VerticalId || null,
                SubVerticalId: SubVerticalId || null,
                FetchEDW: !!FetchEDW,
            }
        })
    }, [LeadDataId, VerticalId, SubVerticalId]);


    useEffect(() => {
        if (!!data) {
            const _leadDetail = JSON.parse(`${data?.LDPIngestQueryGroup?.GetLeadDetail}`)[0];
            let _leadDetailPayload = {};
            if(_leadDetail?.Payload){
                try {
                    _leadDetailPayload = JSON.parse(_leadDetail.Payload);
                } catch (error) {
                    _leadDetailPayload = {"Payload":_leadDetail.Payload};
                }
                delete _leadDetail.Payload;
            }
            
            setLead({..._leadDetail, ..._leadDetailPayload});
        } else if (error) {
            setLead(null);
        }
    }, [data, error]);
    
    return (
        <Paper className={classes.contrainer}>
            {queryLoad([!!loading], [error]) || (lead &&
                <Grid className={classes.mainGrid} container>
                    <Grid item xs={12}>
                        {Object.keys(lead).map(element => {
                            return <Typo key={element} title={element} value={lead[element]} />
                        })}
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contrainer: {
            textAlign: "left",
            overflowY: "auto",
            overflowX: "hidden",
            height: "650px"
        },
        mainGrid: {
            padding: "20px",
        },
        pagetitle: {
            padding: "20px",
            color: "white",
            background: "#457373",
        },
        divider: {
            margin: "10px 0",
        },
        root: {
            "& .MuiTextField-root": {
                width: "100%",
            },
        },
        linkTypo: {
            display: "inline",
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    })
);

export default LeadViewModal;