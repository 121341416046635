import { QueryLazyOptions, useLazyQuery } from "@apollo/react-hooks";
import {
    Button,
    Divider,
    Grid,
    Paper,
    TextField,
    Theme,
    createStyles,
    makeStyles
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GET_LEAD_DETAIL } from "../../common/models/leadDataLookup";
import { GetLeadDetail, GetLeadDetailVariables } from "../../common/models/types/GetLeadDetail";
import { GetSubVertical } from "../../common/models/types/GetSubVertical";
import { GetVertical } from "../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../common/models/vertical";
import { TabProps } from "../../components/tabs";
import { RootState } from "../../state";
import { updateTabContainers } from "../../state/tabsReducer";
import { signalLeadSearchFormSave } from "../../state/leadSearchSectionReducer";
import LeadResultList from "./leadResultsList";
import { convertString as StringUtil } from "../../common/utils/string";

interface LeadSearchFormProps {
    ExternalId: string;
    Email: string;
    Uuid: string;
    PhoneNumber: string;
    ExternalLeadId: string;
    VerticalId: any;
    SubVerticalId: any;
    LeadDataId: any;
}

interface Props {
    dispatch: Function;
    leadsTabs: any;
    leadSearchForm: any;
}

const TAB_CONTAINER = "leads-list-tabs";

const LeadsSearchForm = ({ leadsTabs, dispatch, leadSearchForm }: Props) => {

    // const [getLeads, { data: leadData, called, error, loading, refetch }] = useLazyQuery<GetInsuranceAuto>(GET_INSURANCE_AUTO);
    const [getLeads, { data: leadData, called, error, loading, refetch }] = useLazyQuery<GetLeadDetail, GetLeadDetailVariables>(GET_LEAD_DETAIL, {
        fetchPolicy: 'no-cache'
    });
    const { register, handleSubmit, watch, errors, control } = useForm();
    const [searchVar, setSearchvars] = useState<QueryLazyOptions<GetLeadDetailVariables>>();
    const [uuid, setUuid] = useState();
    const classes = useStyles();

    const [getVerticals, { data: verticalData }] = useLazyQuery<GetVertical>(GET_VERTICAL);
    const [verticalOptions, setVerticalOptions] = useState<any>([]);
    const [vertical, setVertical] = useState<any>(null);

    const [getSubverticals, { data: subVerticalData }] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);
    const [subVerticalOptions, setSubVerticalOptions] = useState<any>([]);
    const [subVertical, setSubVertical] = useState<any>(null);

    useEffect(() => {
        if (verticalData) {
            setVerticalOptions(verticalData?.LDPConfigQueryGroup?.Vertical?.map((item) => (
                {
                    id: item?.VerticalId,
                    label: item?.VerticalName
                }
            )));
        }
        else getVerticals();

        if (!subVerticalData)
            getSubverticals();

    }, [verticalData, subVerticalData]);

    // render vertical options
    // useEffect(() => {
    //     setVertical(verticalOptions?.find((it: any) => it?.id === data?.VerticalId) || null);
    // }, [verticalOptions]);

    // render sub-vertical options based on vertical selected value
    useEffect(() => {
        if (vertical) {
            const subVerticalList = subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map((item) => (
                {
                    id: item?.SubVerticalId,
                    label: item?.SubVerticalName,
                    parent: item?.VerticalId
                }
            )) ?? [];

            const options = subVerticalList.filter((it: any) => it?.parent === vertical.id) ?? [];
            setSubVerticalOptions(options);
            setSubVertical(leadSearchForm.SubVerticalId ? options.find((s:any) => s.id === leadSearchForm?.SubVerticalId) : null);
        }
    }, [vertical]);

    const formError = {
        Email: {
            pattern: {
            //  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Entered value does not match email format"
            }
        },
    };

    const handleCloseTab = (tabs: TabProps[]) => {
        dispatch(
            updateTabContainers({
                [TAB_CONTAINER]: {
                    tabFocus: (tabs.length - 1),
                    tabs: tabs,
                }
            })
        );
    };

    const odsOnly = (vars: GetLeadDetailVariables) => {
        return (StringUtil.isEmpty(vars.Uuid) &&
            StringUtil.isEmpty(vars.ExternalId) &&
            StringUtil.isEmpty(vars.ExternalLeadId) &&
            StringUtil.isEmpty(vars.Email) &&
            StringUtil.isEmpty(vars.PhoneNumber) &&
            StringUtil.isEmpty(vars.LeadDataId) && 
            !!vars.VerticalId && !!vars.SubVerticalId) ||
            (!vars.VerticalId || !vars.SubVerticalId);
    }

    const triggerLeadSearch = (data: LeadSearchFormProps) => {
        setSearchvars({
            variables: {
                Uuid: data.Uuid || null,
                ExternalId: data.ExternalId || null,
                ExternalLeadId: data.ExternalLeadId || null,
                Email: data.Email || null,
                PhoneNumber: data.PhoneNumber || null,
                VerticalId: vertical?.id || null,
                SubVerticalId: subVertical?.id || null,
                LeadDataId: data.LeadDataId || null,
                FetchEDW: false, // default to false and then use true when ODS yields empty STEP 1
            }
        })
    }

    useEffect(() => {
        if(searchVar) {
            getLeads(searchVar);
            dispatch(signalLeadSearchFormSave(searchVar.variables));
        }
    }, [searchVar]);

    useEffect(() => {
        if(leadSearchForm) {
            if(leadSearchForm.VerticalId && verticalOptions){
                setVertical(verticalOptions.find((v:any) => v.id === leadSearchForm?.VerticalId));
            }
        }
    }, [leadSearchForm, verticalOptions]);

    useEffect(() => {
        if(leadData && called && !error){
            if (leadData?.LDPIngestQueryGroup?.GetLeadDetail && leadData?.LDPIngestQueryGroup?.GetLeadDetail !== "[]" && leadData?.LDPIngestQueryGroup?.GetLeadDetail && leadData?.LDPIngestQueryGroup?.GetLeadDetail !== "") {
                var currentTabs = leadsTabs.tabContainers[TAB_CONTAINER].tabs;
                dispatch(updateTabContainers(
                    {
                        [TAB_CONTAINER]: {
                            tabFocus: currentTabs.length,
                            tabs: [
                                ...currentTabs,
                                {
                                    title: `Result #${currentTabs.length}`,
                                    tabId: `leads-${currentTabs.length}`,
                                    content: <LeadResultList fetchEDW={searchVar?.variables?.FetchEDW} data={leadData?.LDPIngestQueryGroup?.GetLeadDetail} verticalId={searchVar?.variables?.VerticalId} subVerticalId={searchVar?.variables?.SubVerticalId} />,
                                    closeTab: handleCloseTab,
                                    permalink: `/leads/`,
                                },
                            ],
                        }
                    }
                ));
            } else if(searchVar && searchVar?.variables?.VerticalId && searchVar?.variables?.SubVerticalId && !searchVar?.variables?.FetchEDW && !odsOnly(searchVar?.variables)){
                    // default to false and then use true when ODS yields empty OPTIONAL STEP 2
                setSearchvars({ ...searchVar, variables: { ...searchVar?.variables, FetchEDW: true } });
            } else {
                toast.error("Search Did Not Yield Any Result!");
            }
        }
        
    }, [leadData]);

    return (
        <Paper className={classes.contrainer}>
            <form className={classes.root} onSubmit={handleSubmit(triggerLeadSearch)}>
            <Grid className={classes.mainGrid} justify="center" container spacing={3}>
                <Grid item lg> </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <Paper className={classes.searchFormContainer}>
                        <h2> Lead Search </h2>
                        <Divider />
                        <TextField
                            inputRef={register(formError.Email)}
                            name="Email"
                            label="Email Address"
                            defaultValue={leadSearchForm?.Email || ""}
                            disabled={loading}
                            variant="outlined"
                            className={classes.searchFormSpace}
                            error={errors.Email && true}
                            helperText={errors.Email && errors.Email?.message}
                            onChange={(evt) => {
                                evt.target.value = evt.target.value.trim();
                            }}
                        />
                        <TextField
                            inputRef={register}
                            name="PhoneNumber"
                            label="Phone Number"
                            defaultValue={leadSearchForm?.PhoneNumber || ""}
                            variant="outlined"
                            disabled={loading}
                            className={classes.searchFormSpace}
                        />
                        <TextField
                            inputRef={register}
                            name="Uuid"
                            label="Internal UUID"
                            defaultValue={leadSearchForm?.Uuid || ""}
                            disabled={loading}
                            variant="outlined"
                            className={classes.searchFormSpace}
                        />
                        <TextField
                            inputRef={register}
                            name="ExternalId"
                            label="Signup ID"
                            disabled={loading}
                            defaultValue={leadSearchForm?.ExternalId || ""}
                            variant="outlined"
                            className={classes.searchFormSpace}
                        />
                        <TextField
                            inputRef={register}
                            name="ExternalLeadId"
                            label="External Lead ID"
                            disabled={loading}
                            defaultValue={leadSearchForm?.ExternalLeadId || ""}
                            variant="outlined"
                            className={classes.searchFormSpace}
                        />

                        <Autocomplete
                            id="vertical-id-input"
                            className={classes.searchFormSpace}
                            options={verticalOptions}
                            value={vertical}
                            disabled={loading}
                            getOptionLabel={option => option.label}
                            onChange={(event: any, newValue: any) => {
                                setVertical(newValue);
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    inputRef={register}
                                    name="VerticalName"
                                    label="Vertical Name"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Autocomplete
                            id="subvertical-id-input"
                            options={subVerticalOptions}
                            value={subVertical}
                            disabled={loading || !vertical}
                            getOptionLabel={option => option.label}
                            className={classes.searchFormSpace}
                            onChange={(event: any, newValue: any) => {
                                setSubVertical(newValue);
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    inputRef={register}
                                    name="SubVerticalName"
                                    label="Subvertical Name"
                                    variant="outlined"
                                />
                            )}
                        />

                        {(vertical && subVertical) ? (<TextField
                            inputRef={register}
                            name="LeadDataId"
                            label="Lead Data ID"
                            defaultValue={leadSearchForm?.LeadDataId || ""}
                            variant="outlined"
                            className={classes.searchFormSpace}
                        />) : ""}

                        <Button
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            type="submit"
                            size="large"
                            fullWidth
                            startIcon={<SearchIcon />}
                            className={classes.searchFormSpace}
                        >
                            Search
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg> </Grid>
            </Grid>
        </form>
            
        </Paper>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contrainer: {
            textAlign: "left",
        },
        searchFormContainer: {
            padding: "30px",
        },
        searchFormSpace: {
            marginTop: "20px",
        },
        mainGrid: {
            padding: "20px",
        },
        pagetitle: {
            padding: "20px",
            color: "white",
            background: "#457373",
        },
        root: {
            "& .MuiTextField-root": {
                width: "100%",
            },
        },
    })
);

export default connect((state: RootState) => ({
    leadsTabs: state.tabsSection,
    leadSearchForm: state.leadSearchSection.leadSearchForm
}), null)(LeadsSearchForm);
